export const pageTitle = 'Rollup reklamowy z wydrukiem standard';
export const pageDescription = 'Szukasz niezwykle prostych w montażu i efektownych rollupów reklamowych? Znajdziesz je u nas wykonane na dedykowanym do systemów wystawienniczych materiale.';
export const mainHeader = 'Rollup reklamowy standard';
export const title = 'Rollup standard';

export const rollupInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**Rollup <span class="orange">Czas realizacji</span>**
      \n ok. 1-2 dni robocze`,
    },
    {
      article: `**Rollup <span class="orange">koszt wysyłki</span>**
      \n 20,00 zł/netto
      `,
    },
  ],
  rightColumn: [
    {
      article: `**Rollup <span class="orange">reklamowy</span>**
      \n Najbardziej ekonomiczny roll-up w ofercie. Łatwość użytkowania i dostępność wielu różnych szerokości sprawia że jest on idealny jako dodatek stoiska, dużych eventach czy
      materiał promocyjny na pokazach i konferencjach.
      \n * mały, poręczny i łatwy w transporcie. Lider cenowy!
      \n * kaseta w całości wykonana z aluminium anodowanego
      \n * listwa „Express Clip” – błyskawiczny i łatwy montaż wydruku
      \n * dwie obrotowe stopy stabilizujące
      `,
    },
    {
      article: `**Rollup z wydrukiem <span class="orange">specyfikacja</span>**
      \n * wysokość rozłożonego systemu: 208cm
      \n * waga systemu wraz z grafiką: ok. 3 - 6 kg (w zależności od szerokości kasety)
      \n * w zestawie: kaseta, maszt z uchwytem, listwa, wydrukowana grafika i torba transportowa
      \n Wydruk wykonany metodą mild solwent na materiale dedykowanym do systemów wystawienniczych - Blockout.
      `,
    },
    {
      article: `**Rollup <span class="orange">wymiary</span>**
      \n * ${title} 85x200cm
      \n * ${title} 100x200cm
      \n * ${title} 120x200cm
      \n * ${title} 150x200cm
      `,
    },
    {
      article: `**Tani rollup <span class="orange">zastosowanie</span>**
      \n * na prezentacjach,
      \n * na konferencjach,
      \n * na spotkaniach biznesowych,
      \n * w biurach,
      \n * na eventach,
      \n * w punktach sprzedaży.
      `,
    },
    {
      article: `**Tanie rollupy <span class="orange">zalety</span>**
      \n * profesjonalny i elegancki wygląd,
      \n * wygodny transport,
      \n * łatwość w rozkładaniu i składaniu,
      \n * znany i sprawdzony,
      \n * ekonomiczny.
      `,
    },
    {
      article: `**Rollup <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
